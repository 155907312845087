import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


import plugins from './plugins' // plugins
Vue.use(plugins)


// 引入公共css文件
import "../src/assets/css/public.css";


// 引入公共js文件
import publicJS from "../src/assets/js/public.js";
Vue.prototype.publicJS = publicJS;



// 引入省市区地址js文件
import siteJS from "../src/assets/js/site.js";
Vue.prototype.siteJS = siteJS;



// 引入左侧导航js文件
// import navJS from "../src/assets/js/nav.js";
// Vue.prototype.navJS = navJS;



//  引入打印（print-js）
import Print from '../src/assets/js/print.js'
Vue.use(Print) // 打印



// 引入element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);




// 引入axios请求
import requrst from "../src/assets/js/requrst.js";
Vue.prototype.$requrst = requrst;



// 引入百度地图
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '4BSh3wKITibIO2Do01oUEp6NLI3eXSF8'//h4TdONBnpV1KG2sM25eRIYfuS7pxb3my
})


// 引入动态背景vue-particles
import VueParticles from 'vue-particles'
Vue.use(VueParticles)




// 注册面包屑导航全局组件
import Breadcrumb from '@/components/Breadcrumb';
Vue.component('Breadcrumb', Breadcrumb)



// 注册分页按钮全局组件
import Paging from '@/components/paging';
Vue.component('Paging', Paging)




// 引入DataV 数据大屏组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)



// 引入数字滚动组件
import CountTo from 'vue-count-to'
Vue.component('CountTo', CountTo)



Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
