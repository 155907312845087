const site = [{
	"regionCode": "53",
	"regionName": "云南省",
	"children": [{
		"regionCode": "5301",
		"regionName": "昆明市",
		"children": [{
			"regionCode": "530102",
			"regionName": "五华区"
		}, {
			"regionCode": "530103",
			"regionName": "盘龙区"
		}, {
			"regionCode": "530111",
			"regionName": "官渡区"
		}, {
			"regionCode": "530112",
			"regionName": "西山区"
		}, {
			"regionCode": "530113",
			"regionName": "东川区"
		}, {
			"regionCode": "530114",
			"regionName": "呈贡区"
		}, {
			"regionCode": "530115",
			"regionName": "晋宁区"
		}, {
			"regionCode": "530124",
			"regionName": "富民县"
		}, {
			"regionCode": "530125",
			"regionName": "宜良县"
		}, {
			"regionCode": "530126",
			"regionName": "石林彝族自治县"
		}, {
			"regionCode": "530127",
			"regionName": "嵩明县"
		}, {
			"regionCode": "530128",
			"regionName": "禄劝彝族苗族自治县"
		}, {
			"regionCode": "530129",
			"regionName": "寻甸回族彝族自治县"
		}, {
			"regionCode": "530181",
			"regionName": "安宁市"
		}]
	}, {
		"regionCode": "5303",
		"regionName": "曲靖市",
		"children": [{
			"regionCode": "530302",
			"regionName": "麒麟区"
		}, {
			"regionCode": "530303",
			"regionName": "沾益区"
		}, {
			"regionCode": "530304",
			"regionName": "马龙区"
		}, {
			"regionCode": "530322",
			"regionName": "陆良县"
		}, {
			"regionCode": "530323",
			"regionName": "师宗县"
		}, {
			"regionCode": "530324",
			"regionName": "罗平县"
		}, {
			"regionCode": "530325",
			"regionName": "富源县"
		}, {
			"regionCode": "530326",
			"regionName": "会泽县"
		}, {
			"regionCode": "530381",
			"regionName": "宣威市"
		}]
	}, {
		"regionCode": "5304",
		"regionName": "玉溪市",
		"children": [{
			"regionCode": "530402",
			"regionName": "红塔区"
		}, {
			"regionCode": "530403",
			"regionName": "江川区"
		}, {
			"regionCode": "530423",
			"regionName": "通海县"
		}, {
			"regionCode": "530424",
			"regionName": "华宁县"
		}, {
			"regionCode": "530425",
			"regionName": "易门县"
		}, {
			"regionCode": "530426",
			"regionName": "峨山彝族自治县"
		}, {
			"regionCode": "530427",
			"regionName": "新平彝族傣族自治县"
		}, {
			"regionCode": "530428",
			"regionName": "元江哈尼族彝族傣族自治县"
		}, {
			"regionCode": "530481",
			"regionName": "澄江市"
		}]
	}, {
		"regionCode": "5305",
		"regionName": "保山市",
		"children": [{
			"regionCode": "530502",
			"regionName": "隆阳区"
		}, {
			"regionCode": "530521",
			"regionName": "施甸县"
		}, {
			"regionCode": "530523",
			"regionName": "龙陵县"
		}, {
			"regionCode": "530524",
			"regionName": "昌宁县"
		}, {
			"regionCode": "530581",
			"regionName": "腾冲市"
		}]
	}, {
		"regionCode": "5306",
		"regionName": "昭通市",
		"children": [{
			"regionCode": "530602",
			"regionName": "昭阳区"
		}, {
			"regionCode": "530621",
			"regionName": "鲁甸县"
		}, {
			"regionCode": "530622",
			"regionName": "巧家县"
		}, {
			"regionCode": "530623",
			"regionName": "盐津县"
		}, {
			"regionCode": "530624",
			"regionName": "大关县"
		}, {
			"regionCode": "530625",
			"regionName": "永善县"
		}, {
			"regionCode": "530626",
			"regionName": "绥江县"
		}, {
			"regionCode": "530627",
			"regionName": "镇雄县"
		}, {
			"regionCode": "530628",
			"regionName": "彝良县"
		}, {
			"regionCode": "530629",
			"regionName": "威信县"
		}, {
			"regionCode": "530681",
			"regionName": "水富市"
		}]
	}, {
		"regionCode": "5307",
		"regionName": "丽江市",
		"children": [{
			"regionCode": "530702",
			"regionName": "古城区"
		}, {
			"regionCode": "530721",
			"regionName": "玉龙纳西族自治县"
		}, {
			"regionCode": "530722",
			"regionName": "永胜县"
		}, {
			"regionCode": "530723",
			"regionName": "华坪县"
		}, {
			"regionCode": "530724",
			"regionName": "宁蒗彝族自治县"
		}]
	}, {
		"regionCode": "5308",
		"regionName": "普洱市",
		"children": [{
			"regionCode": "530802",
			"regionName": "思茅区"
		}, {
			"regionCode": "530821",
			"regionName": "宁洱哈尼族彝族自治县"
		}, {
			"regionCode": "530822",
			"regionName": "墨江哈尼族自治县"
		}, {
			"regionCode": "530823",
			"regionName": "景东彝族自治县"
		}, {
			"regionCode": "530824",
			"regionName": "景谷傣族彝族自治县"
		}, {
			"regionCode": "530825",
			"regionName": "镇沅彝族哈尼族拉祜族自治县"
		}, {
			"regionCode": "530826",
			"regionName": "江城哈尼族彝族自治县"
		}, {
			"regionCode": "530827",
			"regionName": "孟连傣族拉祜族佤族自治县"
		}, {
			"regionCode": "530828",
			"regionName": "澜沧拉祜族自治县"
		}, {
			"regionCode": "530829",
			"regionName": "西盟佤族自治县"
		}]
	}, {
		"regionCode": "5309",
		"regionName": "临沧市",
		"children": [{
			"regionCode": "530902",
			"regionName": "临翔区"
		}, {
			"regionCode": "530921",
			"regionName": "凤庆县"
		}, {
			"regionCode": "530922",
			"regionName": "云县"
		}, {
			"regionCode": "530923",
			"regionName": "永德县"
		}, {
			"regionCode": "530924",
			"regionName": "镇康县"
		}, {
			"regionCode": "530925",
			"regionName": "双江拉祜族佤族布朗族傣族自治县"
		}, {
			"regionCode": "530926",
			"regionName": "耿马傣族佤族自治县"
		}, {
			"regionCode": "530927",
			"regionName": "沧源佤族自治县"
		}]
	}, {
		"regionCode": "5323",
		"regionName": "楚雄彝族自治州",
		"children": [{
			"regionCode": "532301",
			"regionName": "楚雄市"
		}, {
			"regionCode": "532322",
			"regionName": "双柏县"
		}, {
			"regionCode": "532323",
			"regionName": "牟定县"
		}, {
			"regionCode": "532324",
			"regionName": "南华县"
		}, {
			"regionCode": "532325",
			"regionName": "姚安县"
		}, {
			"regionCode": "532326",
			"regionName": "大姚县"
		}, {
			"regionCode": "532327",
			"regionName": "永仁县"
		}, {
			"regionCode": "532328",
			"regionName": "元谋县"
		}, {
			"regionCode": "532329",
			"regionName": "武定县"
		}, {
			"regionCode": "532331",
			"regionName": "禄丰县"
		}]
	}, {
		"regionCode": "5325",
		"regionName": "红河哈尼族彝族自治州",
		"children": [{
			"regionCode": "532501",
			"regionName": "个旧市"
		}, {
			"regionCode": "532502",
			"regionName": "开远市"
		}, {
			"regionCode": "532503",
			"regionName": "蒙自市"
		}, {
			"regionCode": "532504",
			"regionName": "弥勒市"
		}, {
			"regionCode": "532523",
			"regionName": "屏边苗族自治县"
		}, {
			"regionCode": "532524",
			"regionName": "建水县"
		}, {
			"regionCode": "532525",
			"regionName": "石屏县"
		}, {
			"regionCode": "532527",
			"regionName": "泸西县"
		}, {
			"regionCode": "532528",
			"regionName": "元阳县"
		}, {
			"regionCode": "532529",
			"regionName": "红河县"
		}, {
			"regionCode": "532530",
			"regionName": "金平苗族瑶族傣族自治县"
		}, {
			"regionCode": "532531",
			"regionName": "绿春县"
		}, {
			"regionCode": "532532",
			"regionName": "河口瑶族自治县"
		}]
	}, {
		"regionCode": "5326",
		"regionName": "文山壮族苗族自治州",
		"children": [{
			"regionCode": "532601",
			"regionName": "文山市"
		}, {
			"regionCode": "532622",
			"regionName": "砚山县"
		}, {
			"regionCode": "532623",
			"regionName": "西畴县"
		}, {
			"regionCode": "532624",
			"regionName": "麻栗坡县"
		}, {
			"regionCode": "532625",
			"regionName": "马关县"
		}, {
			"regionCode": "532626",
			"regionName": "丘北县"
		}, {
			"regionCode": "532627",
			"regionName": "广南县"
		}, {
			"regionCode": "532628",
			"regionName": "富宁县"
		}]
	}, {
		"regionCode": "5328",
		"regionName": "西双版纳傣族自治州",
		"children": [{
			"regionCode": "532801",
			"regionName": "景洪市"
		}, {
			"regionCode": "532822",
			"regionName": "勐海县"
		}, {
			"regionCode": "532823",
			"regionName": "勐腊县"
		}]
	}, {
		"regionCode": "5329",
		"regionName": "大理白族自治州",
		"children": [{
			"regionCode": "532901",
			"regionName": "大理市"
		}, {
			"regionCode": "532922",
			"regionName": "漾濞彝族自治县"
		}, {
			"regionCode": "532923",
			"regionName": "祥云县"
		}, {
			"regionCode": "532924",
			"regionName": "宾川县"
		}, {
			"regionCode": "532925",
			"regionName": "弥渡县"
		}, {
			"regionCode": "532926",
			"regionName": "南涧彝族自治县"
		}, {
			"regionCode": "532927",
			"regionName": "巍山彝族回族自治县"
		}, {
			"regionCode": "532928",
			"regionName": "永平县"
		}, {
			"regionCode": "532929",
			"regionName": "云龙县"
		}, {
			"regionCode": "532930",
			"regionName": "洱源县"
		}, {
			"regionCode": "532931",
			"regionName": "剑川县"
		}, {
			"regionCode": "532932",
			"regionName": "鹤庆县"
		}]
	}, {
		"regionCode": "5331",
		"regionName": "德宏傣族景颇族自治州",
		"children": [{
			"regionCode": "533102",
			"regionName": "瑞丽市"
		}, {
			"regionCode": "533103",
			"regionName": "芒市"
		}, {
			"regionCode": "533122",
			"regionName": "梁河县"
		}, {
			"regionCode": "533123",
			"regionName": "盈江县"
		}, {
			"regionCode": "533124",
			"regionName": "陇川县"
		}]
	}, {
		"regionCode": "5333",
		"regionName": "怒江傈僳族自治州",
		"children": [{
			"regionCode": "533301",
			"regionName": "泸水市"
		}, {
			"regionCode": "533323",
			"regionName": "福贡县"
		}, {
			"regionCode": "533324",
			"regionName": "贡山独龙族怒族自治县"
		}, {
			"regionCode": "533325",
			"regionName": "兰坪白族普米族自治县"
		}]
	}, {
		"regionCode": "5334",
		"regionName": "迪庆藏族自治州",
		"children": [{
			"regionCode": "533401",
			"regionName": "香格里拉市"
		}, {
			"regionCode": "533422",
			"regionName": "德钦县"
		}, {
			"regionCode": "533423",
			"regionName": "维西傈僳族自治县"
		}]
	}]
},]




export default {
	site,
}
