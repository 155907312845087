// 打印类属性、方法定义
const Print = function (dom, options) {
    if (!(this instanceof Print)) return new Print(dom, options);

    this.options = this.extend({
        noPrint: '.no-print',
        onStart: function () { },
        onEnd: function () { }
    }, options);

    if ((typeof dom) === "string") {
        this.dom = document.querySelector(dom);
    } else {
        this.dom = dom;
    }

    this.init();
};
Print.prototype = {
    init: function () {
        let content = this.getStyle() + this.getHtml();
        this.writeIframe(content);
    },
    extend: function (obj, obj2) {
        for (let k in obj2) {
            obj[k] = obj2[k];
        }
        return obj;
    },

    getStyle: function () {
        let str = "";
        let styles = document.querySelectorAll('style,link');
        for (let i = 0; i < styles.length; i++) {
            str += styles[i].outerHTML;
        }
        str += "<style>" + (this.options.noPrint ? this.options.noPrint : '.no-print') + "{display:none;}</style>";

        return str;
    },

    getHtml: function () {
        let inputs = document.querySelectorAll('input');
        let textareas = document.querySelectorAll('textarea');
        let selects = document.querySelectorAll('select');
        let canvass = document.querySelectorAll('canvas');
        let cells = document.querySelectorAll('.cell');


        for (let k in inputs) {
            if (inputs[k].type === "checkbox" || inputs[k].type === "radio") {
                if (inputs[k].checked === true) {
                    inputs[k].setAttribute('checked', "checked");
                } else {
                    inputs[k].removeAttribute('checked');
                }
            } else if (inputs[k].type === "text") {
                inputs[k].setAttribute('value', inputs[k].value);
            }
        }

        for (let k2 in textareas) {
            if (textareas[k2].type === 'textarea') {
                textareas[k2].innerHTML = textareas[k2].value;
            }
        }

        for (let k3 in selects) {
            if (selects[k3].type === 'select-one') {
                let child = selects[k3].children;
                for (let i in child) {
                    if (child[i].tagName === 'OPTION') {
                        if (child[i].selected === true) {
                            child[i].setAttribute('selected', "selected");
                        } else {
                            child[i].removeAttribute('selected');
                        }
                    }
                }
            }
        }

        //chart转图片
        for (var k4 = 0; k4 < canvass.length; k4++) {
            var imageURL = canvass[k4].toDataURL("image/png");
            var img = document.createElement("img");
            img.src = imageURL;
            img.setAttribute('style', 'max-width: 100%;');
            img.className = 'isNeedRemove'
            canvass[k4].parentNode.style.width = '100%'
            canvass[k4].parentNode.style.height = '500px'
            canvass[k4].parentNode.style.textAlign = 'center'
            canvass[k4].parentNode.insertBefore(img, canvass[k4].nextElementSibling);

        }

        const tableNode = document.querySelectorAll('.el-table__header,.el-table__body');
        //el-table 打印不全的问题
        for (let k6 = 0; k6 < tableNode.length; k6++) {
            const tableItem = tableNode[k6];
            tableItem.style.width = '100%';
            const child = tableItem.childNodes;
            for (let i = 0; i < child.length; i++) {
                const element = child[i];
                if (element.localName === 'colgroup') {
                    element.innerHTML = '';
                }
            }
        }
        //el-table 格子里面打印超过格子的问题
        for (let k7 = 0; k7 < cells.length; k7++) {
            const cell = cells[k7];
            cell.style.width = '100%';
            cell.removeAttribute('style')
        }


        return this.dom.outerHTML;
    },

    writeIframe: function (content) {
        let w;
        let doc;
        let iframe = document.createElement('iframe');
        let f = document.body.appendChild(iframe);
        iframe.id = "myIframe";
        // iframe.style = "position:absolute;width:0;height:0;top:-10px;left:-10px;";
        w = f.contentWindow || f.contentDocument;
        doc = f.contentDocument || f.contentWindow.document;
        doc.open();
        doc.write(content);
        doc.close();

        var removes = document.querySelectorAll('.isNeedRemove');
        for (var k = 0; k < removes.length; k++) {
            removes[k].parentNode.removeChild(removes[k]);
        }
        this.toPrint(w, function () {
            document.body.removeChild(iframe);
        });
    },

    toPrint: function (w, cb) {
        let _this = this;
        w.onload = function () {
            try {
                setTimeout(function () {
                    w.focus();
                    typeof _this.options.onStart === 'function' && _this.options.onStart();
                    if (!w.document.execCommand('print', false, null)) {
                        w.print();
                    }
                    typeof _this.options.onEnd === 'function' && _this.options.onEnd();
                    w.close();
                    cb && cb();
                });
            } catch (err) {
                // console.log('err', err);
            }
        };
    }
};
const MyPlugin = {};
MyPlugin.install = function (Vue, options) {
    // 4. 添加实例方法
    Vue.prototype.$print = Print;
};
export default MyPlugin;





// // 打印类属性、方法定义
// /* eslint-disable */
// const Print = function (dom, options) {
//     if (!(this instanceof Print)) return new Print(dom, options);

//     this.options = this.extend({
//         'noPrint': '.no-print'
//     }, options);

//     if ((typeof dom) === "string") {
//         this.dom = document.querySelector(dom);
//     } else {
//         this.isDOM(dom)
//         this.dom = this.isDOM(dom) ? dom : dom.$el;
//     }

//     this.init();
// };
// Print.prototype = {
//     init: function () {
//         var content = this.getStyle() + this.getHtml();
//         this.writeIframe(content);
//     },
//     extend: function (obj, obj2) {
//         for (var k in obj2) {
//             obj[k] = obj2[k];
//         }
//         return obj;
//     },

//     getStyle: function () {
//         var str = "",
//             styles = document.querySelectorAll('style,link');
//         for (var i = 0; i < styles.length; i++) {
//             str += styles[i].outerHTML;
//         }
//         str += "<style>" + (this.options.noPrint ? this.options.noPrint : '.no-print') + "{display:none;}</style>";
//         str += "<style>html,body,div{height: auto!important;font-size:14px}</style>";
//         return str;
//     },

//     getHtml: function () {
//         var inputs = document.querySelectorAll('input');
//         var textareas = document.querySelectorAll('textarea');
//         var selects = document.querySelectorAll('select');

//         for (var k = 0; k < inputs.length; k++) {
//             if (inputs[k].type == "checkbox" || inputs[k].type == "radio") {
//                 if (inputs[k].checked == true) {
//                     inputs[k].setAttribute('checked', "checked")
//                 } else {
//                     inputs[k].removeAttribute('checked')
//                 }
//             } else if (inputs[k].type == "text") {
//                 inputs[k].setAttribute('value', inputs[k].value)
//             } else {
//                 inputs[k].setAttribute('value', inputs[k].value)
//             }
//         }

//         for (var k2 = 0; k2 < textareas.length; k2++) {
//             if (textareas[k2].type == 'textarea') {
//                 textareas[k2].innerHTML = textareas[k2].value
//             }
//         }

//         for (var k3 = 0; k3 < selects.length; k3++) {
//             if (selects[k3].type == 'select-one') {
//                 var child = selects[k3].children;
//                 for (var i in child) {
//                     if (child[i].tagName == 'OPTION') {
//                         if (child[i].selected == true) {
//                             child[i].setAttribute('selected', "selected")
//                         } else {
//                             child[i].removeAttribute('selected')
//                         }
//                     }
//                 }
//             }
//         }

//         return this.dom.outerHTML;
//     },

//     writeIframe: function (content) {
//         var w, doc, iframe = document.createElement('iframe'),
//             f = document.body.appendChild(iframe);
//         iframe.id = "myIframe";
//         //iframe.style = "position:absolute;width:0;height:0;top:-10px;left:-10px;";
//         iframe.setAttribute('style', 'position:absolute;width:0;height:0;top:-10px;left:-10px;');
//         w = f.contentWindow || f.contentDocument;
//         doc = f.contentDocument || f.contentWindow.document;
//         doc.open();
//         doc.write(content);
//         doc.close();
//         var _this = this
//         iframe.onload = function () {
//             _this.toPrint(w);
//             setTimeout(function () {
//                 document.body.removeChild(iframe)
//             }, 100)
//         }
//     },

//     toPrint: function (frameWindow) {
//         try {
//             setTimeout(function () {
//                 frameWindow.focus();
//                 try {
//                     if (!frameWindow.document.execCommand('print', false, null)) {
//                         frameWindow.print();
//                     }
//                 } catch (e) {
//                     frameWindow.print();
//                 }
//                 frameWindow.close();
//             }, 10);
//         } catch (err) {
//             // console.log('err', err);
//         }
//     },
//     isDOM: (typeof HTMLElement === 'object') ?
//         function (obj) {
//             return obj instanceof HTMLElement;
//         } :
//         function (obj) {
//             return obj && typeof obj === 'object' && obj.nodeType === 1 && typeof obj.nodeName === 'string';
//         }
// };
// const MyPlugin = {}
// MyPlugin.install = function (Vue, options) {
//     // 4. 添加实例方法
//     Vue.prototype.$print = Print
// }
// export default MyPlugin
