<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    // 获取保存的系统信息
    let system = JSON.parse(localStorage.getItem("asdSystem"));
    this.$store.commit("updatedSystemInfo", system);

    this.getDataList(); //获取系统设置信息
  },
  watch: {
    // 监听系统信息赋值
    "$store.state.systemInfo": function () {
      // console.log("系统信息", this.$store.state.systemInfo);
      if (
        this.publicJS.judgment(this.$store.state.systemInfo) ||
        this.publicJS.judgment(this.$store.state.systemInfo.systemName)
      ) {
        document.title = ""; //修改浏览器标题栏
      } else {
        document.title = this.$store.state.systemInfo.systemName; //修改浏览器标题栏
      }
      this.$forceUpdate();
    },
  },
  methods: {
    // 获取系统设置信息
    getDataList() {
      this.$requrst
        .axios_set("/osd/system/systemSet/querySystemSet", "post")
        .then((res) => {
          // console.log("系统设置", res);
          if (res.code == 200 || res.code == "200") {
            localStorage.setItem("asdSystem", JSON.stringify(res.data[0])); //保存系统信息
            this.$store.commit("updatedSystemInfo", res.data[0]);
            document.title = res.data[0].systemName; //修改浏览器标题栏
          }
        })
        .catch((err) => {
          document.title = this.$store.state.systemInfo.systemName;
        });
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
