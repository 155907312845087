// 封装axios请求

import axios from "axios";//引入axios请求
import QS from 'qs';//引入qs请求传参处理
import { Message } from 'element-ui';
import router from "@/router";



const service = axios.create({
    // baseURL: "http://192.168.18.102:8088",//请求地址 //192.168.18.111:8088,//192.168.18.114:8087
    // baseURL: "http://127.0.0.1:9527",//9527
    baseURL: "https://plate.taibanzha.com",//9527
    // baseURL: "https://test.ynwrkj.com/",//服务器"http://192.168.1.32:9527" 
    // baseURL: "http://127.0.0.1:9527",//服务器"http://192.168.1.32:9527"
    //baseURL: "/",
    timeout: 20000,//请求时长
})


service.interceptors.request.use((config) => {
    // console.log(config)
    /* 发送请求时, 需要做的事 */

    // 设置请求头信息, 例如 token
    if (config.type != "login" && config.type != "set" && config.type != "upload" && config.type != "screen") {
        config.headers['token'] = localStorage.getItem('asdToken')
    }



    // 当请求类型为 get 时, 则将 参数拼接
    if (config.method === 'get') {
        config.params = config.data
    }


    // 当请求类型为 post  
    if (config.method === 'post') {
        // 具体传参类型, 根据后端接口要求进行设置
        // 参数类型为 formData 格式时, 设置 Content-Type 为 application/x-www-form-urlencoded
        if (config.ContentType == "" || config.ContentType == undefined || config.ContentType == null) {
            config.headers['Content-Type'] = 'application/json;charsetset=UTF-8'
        } else {
            config.headers['Content-Type'] = config.ContentType
        }
        // config.data = QS.stringify(config.data)
    }


    return config;
}, (error) => {

    return Promise.reject(error);
})



service.interceptors.response.use((response) => {
    // 接口响应时需要做的事
    let res = response.data
    if (response.config.type == "login" || response.config.type == "screen") {
        return Promise.resolve(res)
    } else {
        if (res.code == 200 || res.code == "200") {//正常请求成功
            return Promise.resolve(res)
        } else if (res.code == 401 || res.code == "401") {//登录过期
            // Message.warning(res.msg);
            // Message.warning("登录信息过期，重新登录");
            localStorage.removeItem('asdToken');
            localStorage.removeItem('asdUser');
            router.replace({ path: "/" });
        } else if (res.code == 403 || res.code == "403") {//无权限
            router.replace({ path: "/power" });
        } else if (res.code == 503 || res.code == "503" || res.code == 500 || res.code == "500" ||
            res.status == 503 || res.status == "503" || res.status == 500 || res.status == "500") {
            Message.error("服务器出错，稍后再试！");
        } else {
            Message.warning(res.msg);
        }
    }
}, (error) => {
    if (error.config.type != "screen") {
        Message.error("网络请求异常，请检查网络或稍后再试！")
    }
    return Promise.reject(error)
})



export default service