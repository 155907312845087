import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);


// 路由重复报错处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  // mode: "history",
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    { // 重定向
      path: "/",
      name: "login",
      component: () => import("../views/login/login.vue"),
    },
    { // 登录
      path: "/login/login",
      name: "login",
      component: () => import("../views/login/login.vue"),
    },
    { // 无权限
      path: "/power",
      name: "power",
      component: () => import("../views/power.vue"),
    },
    { // 数据大屏
      path: "/dataBigScreen/index",
      name: "dataBigScreen",
      component: () => import("../views/dataBigScreen/index.vue"),
    },
    { // 渲染页面主要模块
      path: "/homeView",
      name: "home",
      component: () => import("../views/home.vue"),
      children: [
        { // 首页
          path: "/homeView",
          name: "home",
          component: () => import("../views/homeView.vue"),
          meta: { title: '首页', affix: true }
        },
        { // 设备管理-设备分类
          path: "/device/deviceType/deviceTypeList",
          name: "deviceTypeList",
          component: () => import("../views/device/deviceType/deviceTypeList.vue"),
          meta: { title: '设备分类' }
        },
        { // 设备管理-设备分类新增编辑
          path: "/device/deviceType/deviceTypeAddCompile",
          name: "deviceTypeAddCompile",
          component: () => import("../views/device/deviceType/deviceTypeAddCompile.vue"),
          meta: { title: '新增设备分类' }
        },
        { // 设备管理-设备场景
          path: "/device/scene/sceneList",
          name: "sceneList",
          component: () => import("../views/device/scene/sceneList.vue"),
          meta: { title: '设备场景' }
        },
        { // 设备管理-设备场景新增编辑
          path: "/device/scene/sceneAddCompile",
          name: "sceneAddCompile",
          component: () => import("../views/device/scene/sceneAddCompile.vue"),
          meta: { title: '新增设备场景' }
        },
        { // 设备管理-设备分组
          path: "/device/group/groupList",
          name: "groupList",
          component: () => import("../views/device/group/groupList.vue"),
          meta: { title: '设备分组' }
        },
        { // 设备管理-设备分组新增编辑
          path: "/device/group/groupAddCompile",
          name: "groupAddCompile",
          component: () => import("../views/device/group/groupAddCompile.vue"),
          meta: { title: '新增设备分组' }
        },
        { // 设备管理-设备列表
          path: "/device/deviceList/deviceList",
          name: "deviceList",
          component: () => import("../views/device/deviceList/deviceList.vue"),
          meta: { title: '设备列表' }
        },
        { // 设备管理-设备新增编辑
          path: "/device/deviceList/deviceAdd",
          name: "deviceAdd",
          component: () => import("../views/device/deviceList/deviceAddCompile.vue"),
          meta: { title: '新增设备' }
        },
        { // 设备管理-设备新增编辑
          path: "/device/deviceList/deviceCompile",
          name: "deviceCompile",
          component: () => import("../views/device/deviceList/deviceAddCompile.vue"),
          meta: { title: '新增设备' }
        },
        { // 设备管理-设备列表-设备商品
          path: "/device/deviceList/goodsList",
          name: "deviceGoodsList",
          component: () => import("../views/device/deviceList/goodsList.vue"),
          meta: { title: '设备商品' }
        },
        { // 设备管理-设备列表-设备商品新增编辑
          path: "/device/deviceList/goodsAddCompile",
          name: "deviceGoodsAddCompile",
          component: () => import("../views/device/deviceList/goodsAddCompile.vue"),
          meta: { title: '新增设备商品' }
        },
        { // 设备管理-设备列表-设备商品下架记录
          path: "/device/deviceList/goodsDeleteLog",
          name: "goodsDeleteLog",
          component: () => import("../views/device/deviceList/goodsDeleteLog.vue"),
          meta: { title: '设备商品下架' }
        },
        { // 设备管理-设备商品
          path: "/device/deviceGoods/deviceGoods",
          name: "deviceGoods",
          component: () => import("../views/device/deviceGoods/deviceGoods.vue"),
          meta: { title: '设备商品库存' }
        },
        { // 设备管理-设备商品模板
          path: "/device/goodsTemplate/goodsTemplate",
          name: "goodsTemplate",
          component: () => import("../views/device/goodsTemplate/goodsTemplate.vue"),
          meta: { title: '设备商品模板' }
        },
        { // 设备管理-设备商品模板新增编辑
          path: "/device/goodsTemplate/goodsTemplateAddCompile",
          name: "goodsTemplateAddCompile",
          component: () => import("../views/device/goodsTemplate/goodsTemplateAddCompile.vue"),
          meta: { title: '新增设备商品模板' }
        },
        { // 设备管理-设备商品模板已绑设备信息
          path: "/device/goodsTemplate/goodsTemplateDeviceInfo",
          name: "goodsTemplateDeviceInfo",
          component: () => import("../views/device/goodsTemplate/goodsTemplateDeviceInfo.vue"),
          meta: { title: '设备商品模板绑定设备信息' }
        },
        { // 设备管理-设备广告
          path: "/device/advertising/advertisingList",
          name: "deviceAdvertisingList",
          component: () => import("../views/device/advertising/advertisingList.vue"),
          meta: { title: '设备广告' }
        },
        { // 设备管理-设备广告-屏幕广告新增编辑
          path: "/device/advertising/screenAddCompile",
          name: "screenAddCompile",
          component: () => import("../views/device/advertising/screenAddCompile.vue"),
          meta: { title: '新增设备广告' }
        },
        { // 设备管理-设备广告-屏幕广告绑定设备列表
          path: "/device/advertising/screenDevice",
          name: "screenDevice",
          component: () => import("../views/device/advertising/screenDevice.vue"),
          meta: { title: '屏幕广告绑定设备列表' }
        },
        { // 设备管理-设备广告-屏幕广告绑定设备
          path: "/device/advertising/screenDeviceAddCompile",
          name: "screenDeviceAddCompile",
          component: () => import("../views/device/advertising/screenDeviceAddCompile.vue"),
          meta: { title: '屏幕广告绑定设备' }
        },
        { // 设备管理-设备广告-贴纸广告新增编辑
          path: "/device/advertising/paperAddCompile",
          name: "paperAddCompile",
          component: () => import("../views/device/advertising/paperAddCompile.vue"),
          meta: { title: '贴纸广告新增编辑' }
        },
        { // 设备管理-设备广告-贴纸广告绑定设备列表
          path: "/device/advertising/paperDevice",
          name: "paperDevice",
          component: () => import("../views/device/advertising/paperDevice.vue"),
          meta: { title: '贴纸广告绑定设备列表' }
        },
        { // 设备管理-设备广告-贴纸广告绑定设备
          path: "/device/advertising/paperDeviceAddCompile",
          name: "paperDeviceAddCompile",
          component: () => import("../views/device/advertising/paperDeviceAddCompile.vue"),
          meta: { title: '贴纸广告绑定设备' }
        },
        { // 设备管理-设备广告-广告巡检记录
          path: "/device/advertising/checkLog",
          name: "checkLog",
          component: () => import("../views/device/advertising/checkLog.vue"),
          meta: { title: '广告巡检记录' }
        },
        { // 设备管理-设备广告申请记录
          path: "/device/advertisingLog/advertisingLog",
          name: "advertisingLog",
          component: () => import("../views/device/advertisingLog/advertisingLog.vue"),
          meta: { title: '设备广告申请记录' }
        },
        { // 设备管理-生成补货单
          path: "/device/replenish/createReplenish",
          name: "createReplenish",
          component: () => import("../views/device/replenish/createReplenish.vue"),
          meta: { title: '生成补货单' }
        },
        { // 设备管理-设备补货单
          path: "/device/replenish/replenishOrder",
          name: "replenishOrder",
          component: () => import("../views/device/replenish/replenishOrder.vue"),
          meta: { title: '设备补货单' }
        },
        // { // 设备管理-补货记录
        //   path: "/device/replenish/replenishList",
        //   name: "replenishList",
        //   component: () => import("../views/device/replenish/replenishList.vue"),
        // },
        { // 设备管理-设备盘点记录
          path: "/device/deviceCheck/deviceCheckLog",
          name: "deviceCheckLog",
          component: () => import("../views/device/deviceCheck/deviceCheckLog.vue"),
          meta: { title: '设备盘点记录' }
        },
        { // 设备管理-流量卡管理
          path: "/device/card/cardList",
          name: "cardList",
          component: () => import("../views/device/card/cardList.vue"),
          meta: { title: '流量卡管理' }
        },
        { // 设备管理-流量卡管理新增编辑
          path: "/device/card/cardAddCompile",
          name: "cardAddCompile",
          component: () => import("../views/device/card/cardAddCompile.vue"),
          meta: { title: '新增流量卡管理' }
        },
        { // 设备管理-设备日志
          path: "/device/deviceLog/deviceLog",
          name: "deviceLog",
          component: () => import("../views/device/deviceLog/deviceLog.vue"),
          meta: { title: '设备日志' }
        },
        { // 设备管理-设备报修记录
          path: "/device/deviceRepairs/deviceRepairsvLog",
          name: "deviceRepairsvLog",
          component: () => import("../views/device/deviceRepairs/deviceRepairsvLog.vue"),
          meta: { title: '设备报修记录' }
        },
        { // 设备管理-设备异常监测
          path: "/device/abnormal/abnormalList",
          name: "abnormalList",
          component: () => import("../views/device/abnormal/abnormalList.vue"),
          meta: { title: '设备异常监测' }
        },
        { // 设备管理-设备资产配件
          path: "/device/accessory/accessoryList",
          name: "accessoryList",
          component: () => import("../views/device/accessory/accessoryList.vue"),
          meta: { title: '设备资产配件' }
        },
        { // 设备管理-设备资产配件新增编辑
          path: "/device/accessory/accessoryAddCompile",
          name: "accessoryAddCompile",
          component: () => import("../views/device/accessory/accessoryAddCompile.vue"),
          meta: { title: '新增设备资产配件' }
        },
        { // 统计分析-设备地图
          path: "/device/map/map",
          name: "map",
          component: () => import("../views/device/map/map.vue"),
          meta: { title: '设备地图' }
        },
        { // 商户管理-商户列表
          path: "/merchant/merchantList",
          name: "merchantList",
          component: () => import("../views/merchant/partner/merchantList.vue"),
          meta: { title: '商户列表' }
        },
        { // 商户管理-商户新增编辑
          path: "/merchant/merchantAddCompile",
          name: "merchantAddCompile",
          component: () => import("../views/merchant/partner/merchantAddCompile.vue"),
          meta: { title: '新增商户' }
        },
        { // 商户管理-所属设备
          path: "/merchant/deviceList",
          name: "merchantDeviceList",
          component: () => import("../views/merchant/partner/deviceList.vue"),
          meta: { title: '商户所属设备' }
        },
        { // 商户管理-设备新增编辑
          path: "/merchant/deviceAddCompile",
          name: "merchantDeviceAddCompile",
          component: () => import("../views/merchant/partner/deviceAddCompile.vue"),
          meta: { title: '商户管理新增设备' }
        },
        { // 观察者管理-观察者管理
          path: "/merchant/observe/observeList",
          name: "observeList",
          component: () => import("../views/merchant/observe/observeList.vue"),
          meta: { title: '观察者管理' }
        },
        { // 观察者管理-设备绑定解绑
          path: "/merchant/observe/observeDevice",
          name: "observeDevice",
          component: () => import("../views/merchant/observe/observeDevice.vue"),
          meta: { title: '观察者设备绑定解绑' }
        },
        { // 观察者管理-设备绑定
          path: "/merchant/observe/observeDeviceAddCompile",
          name: "observeDeviceAddCompile",
          component: () => import("../views/merchant/observe/observeDeviceAddCompile.vue"),
          meta: { title: '观察者设备绑定' }
        },
        { // 商户管理-合同账单
          path: "/merchant/contract/contractList",
          name: "contractList",
          component: () => import("../views/merchant/contract/contractList.vue"),
          meta: { title: '商户合同账单' }
        },
        { // 商户管理-合同账单新增编辑
          path: "/merchant/contract/contractAddCompile",
          name: "contractAddCompile",
          component: () => import("../views/merchant/contract/contractAddCompile.vue"),
          meta: { title: '商户新增合同账单' }
        },
        { // 商户管理-商户审核
          path: "/merchant/merchantAudit/merchant",
          name: "merchantAudit",
          component: () => import("../views/merchant/merchantAudit/merchant.vue"),
          meta: { title: '商户审核' }
        },
        { // 商品管理-商品品牌
          path: "/goods/brand/brandList",
          name: "brandList",
          component: () => import("../views/goods/brand/brandList.vue"),
          meta: { title: '商品品牌' }
        },
        { // 商品管理-商品品牌新增编辑
          path: "/goods/brand/brandAddCompile",
          name: "brandAddCompile",
          component: () => import("../views/goods/brand/brandAddCompile.vue"),
          meta: { title: '新增商品品牌' }
        },
        { // 商品管理-商品品类
          path: "/goods/goodsType/goodsType",
          name: "goodsType",
          component: () => import("../views/goods/goodsType/goodsType.vue"),
          meta: { title: '商品品类' }
        },
        { // 商品管理-商品品类新增编辑
          path: "/goods/goodsType/goodsTypeAddCompile",
          name: "goodsTypeAddCompile",
          component: () => import("../views/goods/goodsType/goodsTypeAddCompile.vue"),
          meta: { title: '新增商品品类' }
        },
        { // 商品管理-商品供应商
          path: "/goods/supplier/supplierList",
          name: "supplierList",
          component: () => import("../views/goods/supplier/supplierList.vue"),
          meta: { title: '商品供应商' }
        },
        { // 商品管理-商品供应商新增编辑
          path: "/goods/supplier/supplierAddCompile",
          name: "supplierAddCompile",
          component: () => import("../views/goods/supplier/supplierAddCompile.vue"),
          meta: { title: '新商品供应商' }
        },
        { // 商品管理-商品列表
          path: "/goods/goodsList/goodsList",
          name: "goodsList",
          component: () => import("../views/goods/goodsList/goodsList.vue"),
          meta: { title: '商品列表' }
        },
        { // 商品管理-商品新增编辑
          path: "/goods/goodsList/goodsAddCompile",
          name: "goodsAddCompile",
          component: () => import("../views/goods/goodsList/goodsAddCompile.vue"),
          meta: { title: '新增商品' }
        },
        { // 商品管理-商品云库
          path: "/goods/CloudTank/CloudTankList",
          name: "CloudTankList",
          component: () => import("../views/goods/CloudTank/CloudTankList.vue"),
          meta: { title: '商品云库' }
        },
        { // 商品管理-商品云库新增编辑
          path: "/goods/CloudTank/CloudTankAddCompile",
          name: "CloudTankAddCompile",
          component: () => import("../views/goods/CloudTank/CloudTankAddCompile.vue"),
          meta: { title: '新增商品云库' }
        },
        { // 商品管理-云库申请记录
          path: "/goods/CloudTankLog/CloudTankLogList",
          name: "CloudTankLogList",
          component: () => import("../views/goods/CloudTankLog/CloudTankLogList.vue"),
          meta: { title: '商品云库申请记录' }
        },
        { // 商品管理-商品销售客户
          path: "/goods/sellClient/sellClient",
          name: "sellClient",
          component: () => import("../views/goods/sellClient/sellClientList.vue"),
          meta: { title: '商品销售客户' }
        },
        { // 商品管理-商品销售客户新增编辑
          path: "/goods/sellClient/sellClientAddCompile",
          name: "sellClientAddCompile",
          component: () => import("../views/goods/sellClient/sellClientAddCompile.vue"),
          meta: { title: '新增商品销售客户' }
        },
        { // 库存管理-仓库信息
          path: "/stock/warehouse/warehouseList",
          name: "warehouseList",
          component: () => import("../views/stock/warehouse/warehouseList.vue"),
          meta: { title: '仓库信息' }
        },
        { // 库存管理-仓库新增编辑
          path: "/stock/warehouse/warehouseAddCompile",
          name: "warehouseAddCompile",
          component: () => import("../views/stock/warehouse/warehouseAddCompile.vue"),
          meta: { title: '新增仓库' }
        },
        { // 库存管理-仓库库存
          path: "/stock/inventory/inventoryList",
          name: "inventoryList",
          component: () => import("../views/stock/inventory/inventoryList.vue"),
          meta: { title: '仓库库存' }
        },
        { // 库存管理-仓库采购
          path: "/stock/inventory/inventoryInPick",
          name: "inventoryInPick",
          component: () => import("../views/stock/inventory/inventoryInPick.vue"),
          meta: { title: '采购订单' }
        },
        { // 库存管理-仓库入库
          path: "/stock/inventory/inventoryIn",
          name: "inventoryIn",
          component: () => import("../views/stock/inventory/inventoryIn.vue"),
          meta: { title: '入库' }
        },
        { // 库存管理-仓库销售
          path: "/stock/inventory/inventoryInSell",
          name: "inventoryInSell",
          component: () => import("../views/stock/inventory/inventoryInSell.vue"),
          meta: { title: '销售' }
        },
        { // 库存管理-仓库出库
          path: "/stock/inventory/inventoryOut",
          name: "inventoryOut",
          component: () => import("../views/stock/inventory/inventoryOut.vue"),
          meta: { title: '出库' }
        },
        { // 库存管理-仓库调拨
          path: "/stock/inventory/inventoryAllocate",
          name: "inventoryAllocate",
          component: () => import("../views/stock/inventory/inventoryAllocate.vue"),
          meta: { title: '仓库调拨' }
        },
        { // 库存管理-仓库库存商品新增编辑
          path: "/stock/inventory/inventoryAddCompile",
          name: "inventoryAddCompile",
          component: () => import("../views/stock/inventory/inventoryAddCompile.vue"),
          meta: { title: '新增仓库库存商品' }
        },
        { // 库存管理-采购单记录
          path: "/stock/purchaseOrder/purchaseOrder",
          name: "purchaseOrder",
          component: () => import("../views/stock/purchaseOrder/purchaseOrder.vue"),
          meta: { title: '采购单记录' }
        },
        { // 库存管理-采购明细
          path: "/stock/purchaseLog/purchaseLog",
          name: "purchaseLog",
          component: () => import("../views/stock/purchaseLog/purchaseLog.vue"),
          meta: { title: '采购明细' }
        },
        { // 库存管理-入库单记录
          path: "/stock/inOrder/inOrder",
          name: "inOrder",
          component: () => import("../views/stock/inOrder/inOrder.vue"),
          meta: { title: '入库单记录' }
        },
        { // 库存管理-入库记录
          path: "/stock/inLog/inLogList",
          name: "inLogList",
          component: () => import("../views/stock/inLog/inLogList.vue"),
          meta: { title: '入库记录' }
        },
        { // 库存管理-销售单记录
          path: "/stock/sellOrder/sellOrder",
          name: "sellOrder",
          component: () => import("../views/stock/sellOrder/sellOrder.vue"),
          meta: { title: '销售单记录' }
        },
        { // 库存管理-销售记录
          path: "/stock/sellLog/sellLog",
          name: "sellLog",
          component: () => import("../views/stock/sellLog/sellLog.vue"),
          meta: { title: '销售记录' }
        },
        { // 库存管理-出库单记录
          path: "/stock/outOrder/outOrder",
          name: "outOrder",
          component: () => import("../views/stock/outOrder/outOrder.vue"),
          meta: { title: '出库单记录' }
        },
        { // 库存管理-出库记录
          path: "/stock/outLog/outLogList",
          name: "outLogList",
          component: () => import("../views/stock/outLog/outLogList.vue"),
          meta: { title: '出库记录' }
        },
        { // 库存管理-调拨单记录
          path: "/stock/allocateOrder/allocateOrder",
          name: "allocateOrder",
          component: () => import("../views/stock/allocateOrder/allocateOrder.vue"),
          meta: { title: '调拨单记录' }
        },
        { // 库存管理-调拨记录
          path: "/stock/allocate/allocateList",
          name: "allocateList",
          component: () => import("../views/stock/allocate/allocateList.vue"),
          meta: { title: '调拨记录' }
        },
        { // 库存管理-盘点单记录
          path: "/stock/checkOrder/checkOrder",
          name: "checkOrder",
          component: () => import("../views/stock/checkOrder/checkOrder.vue"),
          meta: { title: '盘点单记录' }
        },
        // { // 盘点记录-货物盘点
        //   path: "/check/check/check",
        //   name: "goodsCheck",
        //   component: () => import("../views/check/check/check.vue"),
        // },
        // { // 盘点记录-盘点记录
        //   path: "/check/checkLog/checkLog",
        //   name: "goodsCheckLog",
        //   component: () => import("../views/check/checkLog/checkLog.vue"),
        // },
        // { // 盘点记录-盘点记录明细
        //   path: "/check/checkLog/checkDetails",
        //   name: "checkDetails",
        //   component: () => import("../views/check/checkLog/checkDetails.vue"),
        // },
        { // 广告管理-广告推送
          path: "/advertising/advertisingList",
          name: "advertisingList",
          component: () => import("../views/advertising/advertisingList.vue"),
          meta: { title: '广告推送' }
        },
        { // 广告管理-广告推送新增编辑
          path: "/advertising/advertisingAddCompile",
          name: "advertisingAddCompile",
          component: () => import("../views/advertising/advertisingAddCompile.vue"),
          meta: { title: '新增广告推送' }
        },
        { // 广告管理-已绑设备
          path: "/advertising/advertisingDevice",
          name: "advertisingDevice",
          component: () => import("../views/advertising/advertisingDevice.vue"),
          meta: { title: '广告已绑设备' }
        },
        { // 广告管理-已绑绑定设备
          path: "/advertising/advertisingDeviceAddCompile",
          name: "advertisingDeviceAddCompile",
          component: () => import("../views/advertising/advertisingDeviceAddCompile.vue"),
          meta: { title: '广告已绑设备' }
        },
        { // 广告管理-广告推送-广告观看详情
          path: "/advertising/watchList",
          name: "watchList",
          component: () => import("../views/advertising/watchList.vue"),
          meta: { title: '广告观看详情' }
        },
        { // 会员管理-会员列表
          path: "/VIP/VIPList",
          name: "VIPList",
          component: () => import("../views/VIP/VIPList.vue"),
          meta: { title: '会员列表' }
        },
        { // 会员管理-会员列表-购物记录
          path: "/VIP/shoppingLog",
          name: "shoppingLog",
          component: () => import("../views/VIP/shoppingLog.vue"),
          meta: { title: '会员购物记录' }
        },
        { // 会员管理-会员列表-积分记录
          path: "/VIP/integral",
          name: "integral",
          component: () => import("../views/VIP/integral.vue"),
          meta: { title: '会员积分记录' }
        },
        { // 订单管理-全部订单
          path: "/order/allOrder/allOrder",
          name: "allOrder",
          component: () => import("../views/order/allOrder/allOrder.vue"),
          meta: { title: '全部订单' }
        },
        { // 订单管理-订单管理
          path: "/order/order/orderManagement",
          name: "orderManagement",
          component: () => import("../views/order/order/orderManagement.vue"),
          meta: { title: '订单管理' }
        },
        { // 订单管理-异常订单
          path: "/order/order/abnormalOrder",
          name: "abnormalOrder",
          component: () => import("../views/order/order/abnormalOrder.vue"),
          meta: { title: '异常订单' }
        },
        { // 订单管理-无效订单
          path: "/order/order/invalidOrder",
          name: "invalidOrder",
          component: () => import("../views/order/order/invalidOrder.vue"),
          meta: { title: '无效订单' }
        },
        { // 订单管理-纠正订单
          path: "/order/correction/correction",
          name: "correction",
          component: () => import("../views/order/correction/correction.vue"),
          meta: { title: '纠正订单' }
        },
        { // 订单管理-退款审核
          path: "/order/Refund/RefundList",
          name: "RefundList",
          component: () => import("../views/order/Refund/RefundList.vue"),
          meta: { title: '退款审核' }
        },
        { // 订单管理-可乐订单
          path: "/order/cokeOrder/cokeOrderList",
          name: "cokeOrderList",
          component: () => import("../views/order/cokeOrder/cokeOrderList.vue"),
          meta: { title: '可乐订单' }
        },
        { // 订单管理-对账明细
          path: "/order/reconciliation/reconciliation",
          name: "reconciliation",
          component: () => import("../views/order/reconciliation/reconciliation.vue"),
          meta: { title: '对账明细' }
        },
        { // 订单管理-订单设置
          path: "/order/orderSet/orderSet",
          name: "orderSet",
          component: () => import("../views/order/orderSet/orderSet.vue"),
          meta: { title: '订单字典设置' }
        },
        { // 统计分析-收入统计
          path: "/statistical/income/incomeList",
          name: "incomeList",
          component: () => import("../views/statistical/income/incomeList.vue"),
          meta: { title: '收入统计' }
        },
        { // 统计分析-收入统计-详细收入
          path: "/statistical/income/incomeMore",
          name: "incomeMore",
          component: () => import("../views/statistical/income/incomeMore.vue"),
          meta: { title: '详细收入' }
        },
        { // 统计分析-预测统计
          path: "/statistical/forecast/forecastList",
          name: "forecastList",
          component: () => import("../views/statistical/forecast/forecastList.vue"),
          meta: { title: '预测统计' }
        },
        { // 统计分析-销量统计
          path: "/statistical/sales/salesList",
          name: "salesList",
          component: () => import("../views/statistical/sales/salesList.vue"),
          meta: { title: '销量简报统计' }
        },
        { // 统计分析-设备补货统计
          path: "/statistical/replenish/replenishList",
          name: "replenishList",
          component: () => import("../views/statistical/replenish/replenishList.vue"),
          meta: { title: '设备补货统计' }
        },
        { // 统计分析-商品销售统计
          path: "/statistical/goods/goodsList",
          name: "statisticalgoodsList",
          component: () => import("../views/statistical/goods/goodsList.vue"),
          meta: { title: '商品销售统计' }
        },
        { // 统计分析-商品销售统计-商品所售设备销售统计
          path: "/statistical/goods/goodsDevice",
          name: "statisticalgoodsDevice",
          component: () => import("../views/statistical/goods/goodsDevice.vue"),
          meta: { title: '商品所售设备销售统计' }
        },
        { // 统计分析-设备销售统计
          path: "/statistical/device/deviceList",
          name: "statisticalDeviceList",
          component: () => import("../views/statistical/device/deviceList.vue"),
          meta: { title: '设备销售统计' }
        },
        { // 统计分析-设备销售统计-设备所售商品销售统计
          path: "/statistical/device/deviceGoods",
          name: "statisticalDeviceGoods",
          component: () => import("../views/statistical/device/deviceGoods.vue"),
          meta: { title: '设备所售商品销售统计' }
        },
        { // 统计分析-货道信息
          path: "/statistical/Freight/FreightList",
          name: "FreightList",
          component: () => import("../views/statistical/Freight/FreightList.vue"),
          meta: { title: '货道信息' }
        },
        // { // 运营管理-活动管理
        //   path: "/operations/activity/activityList",
        //   name: "activityList",
        //   component: () => import("../views/operations/activity/activityList.vue"),
        // },
        // { // 运营管理-活动数据
        //   path: "/operations/activity/activityData",
        //   name: "activityData",
        //   component: () => import("../views/operations/activity/activityData.vue"),
        // },
        // { // 运营管理-活动新增编辑
        //   path: "/operations/activity/activityAddCompile",
        //   name: "activityAddCompile",
        //   component: () => import("../views/operations/activity/activityAddCompile.vue"),
        // },
        { // 运营管理-微信优惠券
          path: "/operations/coupon/couponList",
          name: "couponList",
          component: () => import("../views/operations/coupon/couponList.vue"),
          meta: { title: '微信优惠券' }
        },
        { // 运营管理-微信优惠券新增
          path: "/operations/coupon/couponAddCompile",
          name: "couponAddCompile",
          component: () => import("../views/operations/coupon/couponAddCompile.vue"),
          meta: { title: '新增微信优惠券' }
        },
        { // 运营管理-微信优惠券领取详情
          path: "/operations/coupon/couponReceive",
          name: "couponReceive",
          component: () => import("../views/operations/coupon/couponReceive.vue"),
          meta: { title: '微信优惠券领取详情' }
        },
        { // 运营管理-系统优惠券
          path: "/operations/systemCoupon/coupon",
          name: "systemCoupon",
          component: () => import("../views/operations/systemCoupon/coupon.vue"),
          meta: { title: '系统优惠券' }
        },
        { // 运营管理-系统优惠券新增
          path: "/operations/systemCoupon/couponAddCompile",
          name: "systemCouponAddCompile",
          component: () => import("../views/operations/systemCoupon/couponAddCompile.vue"),
          meta: { title: '新增系统优惠券' }
        },
        { // 运营管理-系统优惠券领取详情
          path: "/operations/systemCoupon/couponReceive",
          name: "systemCouponReceive",
          component: () => import("../views/operations/systemCoupon/couponReceive.vue"),
          meta: { title: '系统优惠券领取详情' }
        },
        { // 运营管理-系统优惠券使用记录
          path: "/operations/systemCoupon/usageLog",
          name: "usageLog",
          component: () => import("../views/operations/systemCoupon/usageLog.vue"),
          meta: { title: '系统优惠券使用记录' }
        },
        { // 运营管理-溯源台账
          path: "/operations/ledger/ledgerList",
          name: "ledgerList",
          component: () => import("../views/operations/ledger/ledgerList.vue"),
          meta: { title: '溯源台账' }
        },
        { // 结算管理-账户与提现
          path: "/settlement/accounts/accountsList",
          name: "accountsList",
          component: () => import("../views/settlement/accounts/accountsList.vue"),
          meta: { title: '账户明细' }
        },
        { // 结算管理-分账明细
          path: "/settlement/deci/deciList",
          name: "deciList",
          component: () => import("../views/settlement/deci/deciList.vue"),
          meta: { title: '分账明细' }
        },
        // { // 结算管理-修改支付密码
        //   path: "/settlement/accounts/Password",
        //   name: "accountsPassword",
        //   component: () => import("../views/settlement/accounts/Password.vue"),
        // },
        // { // 结算管理-资金明细
        //   path: "/settlement/capital/capitalList",
        //   name: "capitalList",
        //   component: () => import("../views/settlement/capital/capitalList.vue"),
        // },
        // { // 结算管理-提现审核
        //   path: "/settlement/Withdraw/WithdrawList",
        //   name: "WithdrawList",
        //   component: () => import("../views/settlement/Withdraw/WithdrawList.vue"),
        // },
        // { // 结算管理-平台使用费
        //   path: "/settlement/usage/usageList",
        //   name: "usageList",
        //   component: () => import("../views/settlement/usage/usageList.vue"),
        // },
        { // 系统管理-管理员管理
          path: "/system/Admin/AdminList",
          name: "AdminList",
          component: () => import("../views/system/Admin/AdminList.vue"),
          meta: { title: '管理员管理' }
        },
        { // 系统管理-管理员新增编辑
          path: "/system/Admin/AdminAddCompile",
          name: "AdminAddCompile",
          component: () => import("../views/system/Admin/AdminAddCompile.vue"),
          meta: { title: '新增管理员' }
        },
        { // 系统管理-角色管理
          path: "/system/role/roleList",
          name: "roleList",
          component: () => import("../views/system/role/roleList.vue"),
          meta: { title: '角色管理' }
        },
        { // 系统管理-角色新增编辑
          path: "/system/role/roleAddCompile",
          name: "roleAddCompile",
          component: () => import("../views/system/role/roleAddCompile.vue"),
          meta: { title: '新增角色' }
        },
        { // 系统管理-菜单管理
          path: "/system/menu/menuList",
          name: "menuList",
          component: () => import("../views/system/menu/menuList.vue"),
          meta: { title: '菜单管理' }
        },
        { // 系统管理-PC菜单新增编辑
          path: "/system/menu/PCAddCompile",
          name: "PCAddCompile",
          component: () => import("../views/system/menu/PCAddCompile.vue"),
          meta: { title: '新增PC菜单' }
        },
        { // 系统管理-小程序菜单新增编辑
          path: "/system/menu/AppletAddCompile",
          name: "AppletAddCompile",
          component: () => import("../views/system/menu/AppletAddCompile.vue"),
          meta: { title: '新增小程序菜单' }
        },
        { // 系统管理-客服管理
          path: "/system/service/serviceList",
          name: "serviceList",
          component: () => import("../views/system/service/serviceList.vue"),
          meta: { title: '客服管理' }
        },
        { // 系统管理-客服新增编辑
          path: "/system/service/serviceAddCompile",
          name: "serviceAddCompile",
          component: () => import("../views/system/service/serviceAddCompile.vue"),
          meta: { title: '新增客服' }
        },
        { // 系统管理-协议管理
          path: "/system/agreement/agreementList",
          name: "agreementList",
          component: () => import("../views/system/agreement/agreementList.vue"),
          meta: { title: '协议管理' }
        },
        { // 系统管理-协议新增编辑
          path: "/system/agreement/agreementAddCompile",
          name: "agreementAddCompile",
          component: () => import("../views/system/agreement/agreementAddCompile.vue"),
          meta: { title: '新增协议' }
        },
        { // 系统管理-操作日志
          path: "/system/log/logList",
          name: "logList",
          component: () => import("../views/system/log/logList.vue"),
          meta: { title: '操作日志' }
        },
        // { // 系统管理-故障监控
        //   path: "/system/fault/faultList",
        //   name: "faultList",
        //   component: () => import("../views/system/fault/faultList.vue"),
        // },
        { // 系统管理-系统通知
          path: "/system/inform/informList",
          name: "informList",
          component: () => import("../views/system/inform/informList.vue"),
          meta: { title: '系统通知' }
        },
        { // 系统管理-系统通知新增编辑
          path: "/system/inform/informAddCompile",
          name: "informAddCompile",
          component: () => import("../views/system/inform/informAddCompile.vue"),
          meta: { title: '新增系统通知' }
        },
        { // 系统管理-消息通知
          path: "/system/message/messageList",
          name: "messageList",
          component: () => import("../views/system/message/messageList.vue"),
          meta: { title: '消息通知' }
        },
        { // 系统管理-消息通知新增编辑
          path: "/system/message/messageAddCompile",
          name: "messageAddCompile",
          component: () => import("../views/system/message/messageAddCompile.vue"),
          meta: { title: '新增消息通知' }
        },
        { // 系统管理-消息通知发送
          path: "/system/message/messageSend",
          name: "messageSend",
          component: () => import("../views/system/message/messageSend.vue"),
          meta: { title: '发送消息通知' }
        },
        { // 系统管理-消息通知记录
          path: "/system/message/messageLog",
          name: "messageLog",
          component: () => import("../views/system/message/messageLog.vue"),
          meta: { title: '消息通知记录' }
        },
        { // 系统管理-微信订阅
          path: "/system/wechat/subscriptionList",
          name: "subscriptionList",
          component: () => import("../views/system/wechat/subscriptionList.vue"),
          meta: { title: '微信订阅' }
        },
        { // 系统管理-微信订阅新增编辑
          path: "/system/wechat/subscriptionAddCompile",
          name: "subscriptionAddCompile",
          component: () => import("../views/system/wechat/subscriptionAddCompile.vue"),
          meta: { title: '新增微信订阅' }
        },
        { // 系统管理-系统设置
          path: "/system/set/set",
          name: "set",
          component: () => import("../views/system/set/set.vue"),
          meta: { title: '系统设置' }
        },
        { // 系统管理-个人中心
          path: "/system/personal/personal",
          name: "personal",
          component: () => import("../views/system/personal/personal.vue"),
          meta: { title: '个人中心' }
        },
        { // 补货管理-补货员管理
          path: "/replenish/people/peopleList",
          name: "peopleList",
          component: () => import("../views/replenish/people/peopleList.vue"),
          meta: { title: '运维工程师管理' }
        },
        { // 补货管理-补货员绑定（解绑）设备
          path: "/replenish/people/peopleDevice",
          name: "peopleDevice",
          component: () => import("../views/replenish/people/peopleDevice.vue"),
          meta: { title: '运维工程师绑定（解绑）设备' }
        },
        { // 补货管理-补货员绑定设备
          path: "/replenish/people/peopleDeviceAddCompile",
          name: "peopleDeviceAddCompile",
          component: () => import("../views/replenish/people/peopleDeviceAddCompile.vue"),
          meta: { title: '运维工程师绑定设备' }
        },
        { // 补货管理-补货员工作记录
          path: "/replenish/work/workList",
          name: "workList",
          component: () => import("../views/replenish/work/workList.vue"),
          meta: { title: '运维工程师工作记录' }
        },
        { // 导出记录-数据导出记录
          path: "/export/exportLog",
          name: "exportLog",
          component: () => import("../views/export/exportLog.vue"),
          meta: { title: '数据导出记录' }
        },
        // { // AED-AED列表
        //   path: "/aed/aedList",
        //   name: "aedList",
        //   component: () => import("../views/aed/aedList.vue"),
        // },
        // { // AED-AED新增编辑
        //   path: "/aed/aedAddCompile",
        //   name: "aedAddCompile",
        //   component: () => import("../views/aed/aedAddCompile.vue"),
        // },
        // { // AED审核-AED审核列表
        //   path: "/aed/aedExamine",
        //   name: "aedExamine",
        //   component: () => import("../views/aed/aedExamine.vue"),
        // },
        // { // AED审核-急救人员列表
        //   path: "/aed/aidList",
        //   name: "aidList",
        //   component: () => import("../views/aed/aidList.vue"),
        // },
        // { // AED审核-急救人员新增编辑
        //   path: "/aed/aidAddCompile",
        //   name: "aidAddCompile",
        //   component: () => import("../views/aed/aidAddCompile.vue"),
        // },
        // { // AED审核-急救人员审核
        //   path: "/aed/aidExamine",
        //   name: "aidExamine",
        //   component: () => import("../views/aed/aidExamine.vue"),
        // },
      ]
    },
  ]
});


// 路由守卫拦截是否登录
router.beforeEach((to, from, next) => {
  if (to.fullPath == "/dataBigScreen/index") {//跳过数据大屏拦截验证
    return next()
  } else {
    const tokenStr = localStorage.getItem('asdToken');
    // console.log(tokenStr)
    if (to.path == "/login/login") {
      return next()
    } else if (tokenStr == "" || tokenStr == null || tokenStr == undefined) {
      return next("/login/login")
    } else {
    }



    return next()
  }
})






export default router;