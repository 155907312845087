import requestApi from './axios'

// 封装请求接口
const axios = (url, type, data) => {
    return requestApi({
        url: url,
        method: type,
        data: data,
    })
}

// 登录请求
const axios_login = (url, method, data) => {
    return requestApi({
        url: url,
        method: method,
        data: data,
        type: "login"
    })
}

// 获取系统设置
const axios_set = (url, method, data) => {
    return requestApi({
        url: url,
        method: method,
        data: data,
        type: "set"
    })
}


// 数据导出
const axios_export = (url, method, data) => {
    return requestApi({
        url: url,
        method: method,
        data: data,
        type: "export"
    })
}





// 文件上传
const axios_Upload = (url, method, data) => {
    return requestApi({
        url: url,
        method: method,
        data: data,
        type: "upload"
    })
}




// 数据大屏
const axios_screen = (url, method, data) => {
    return requestApi({
        url: url,
        method: method,
        data: data,
        type: "screen"
    })
}





// 自定义 请求头和timeout封装
const axios_custom = (url, method, data, timeout, ContentType) => {
    return requestApi({
        url: url,
        method: method,
        data: data,
        timeout: timeout,//动态修改请求时长
        ContentType: ContentType,//动态修改请求头
    })
}
export default {
    axios,
    axios_login,
    axios_set,
    axios_export,
    axios_Upload,
    axios_screen,
    axios_custom,
}
