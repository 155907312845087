<!-- 面包屑导航组件 -->
<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        v-for="(item, index) in breadcrumb"
        :key="index"
        :class="{ now: index == breadcrumb.length - 1 }"
        >{{ item }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: ["breadcrumb"],
};
</script>

<style lang="scss" scoped>
::v-deep .now .el-breadcrumb__inner {
  font-weight: 700;
  color: #333333;
}
</style>