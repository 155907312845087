<!-- 分页按钮组件 -->
<template>
  <div class="paging">
    <el-select v-model="limit" placeholder="请选择" @change="selectLimit">
      <el-option
        v-for="item in limitList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-pagination
      background
      layout="total,prev, pager, next"
      @current-change="handleCurrentChange"
      :page-size="limit"
      :current-page="page"
      :total="data_count"
    >
    </el-pagination>
    <div class="skip">
      <span>前往第</span>
      <el-input
        @input="inputSkip"
        v-model="skip"
        placeholder="请输入"
      ></el-input>
      <span>页</span>
    </div>
    <el-button @click="toSkip()">确 定</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1, //当前分页
      limit: 20, //每页显示数量
      data_count: 0, //条目总数
      skip: 1, //需要跳转前往的输入页码
      limitList: [
        { label: "20条/页", value: 20 },
        { label: "30条/页", value: 30 },
        { label: "50条/页", value: 50 },
        { label: "100条/页", value: 100 },
        { label: "200条/页", value: 200 },
        { label: "500条/页", value: 500 },
      ], //每页显示的条数列表
    };
  },
  methods: {
    // 点击第几页
    handleCurrentChange(currentPage) {
      this.page = currentPage;
      this.skip = currentPage;
      this.$emit("getPagingData", this.page); //传值到父组件
    },

    //选择每页显示多少条
    selectLimit() {
      this.page = 1;
      this.skip = 1;
      this.$emit("getPagingData", this.page); //传值到父组件
    },

    // 点击跳转前往页
    toSkip() {
      this.page = Number(this.skip);
      this.$emit("getPagingData", this.page); //传值到父组件
    },

    // 输入跳转页码正则处理
    inputSkip() {
      if (this.publicJS.integer(this.skip)) {
        this.skip = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.paging,
.skip {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.paging .el-select {
  width: 105px;
  margin-right: 10px;
}

::v-deep .el-input__inner {
  line-height: 30px;
  height: 30px;
  font-size: 13px;
}

::v-deep .el-input__suffix .el-input__icon {
  line-height: 30px;
}

.skip span {
  font-size: 13px;
}

.skip .el-input {
  width: 50px;
  margin: 0 10px;
}

::v-deep .skip .el-input .el-input__inner {
  padding: 0 !important;
  text-align: center;
}

.paging button {
  width: 70px;
  height: 30px;
  line-height: 30px;
  margin-left: 10px;
  padding: 0;
  font-size: 13px;
}
</style>