/* 公共js文件 */

import router from "@/router"
import { Message } from 'element-ui';
import siteJs from "./site";




// 获取域名地址
// let DomainName = "https://test.ynwrkj.com/";
// let DomainName = "http://192.168.1.51:9527";
// let DomainName = "https://" + window.location.host + "/";
let DomainName = ""



// 模板下载地址
let downloadAddress = {
    deviceInfo: "osd/equipment/euq/exportEquDetailsTemplate",//设备列表
    goodsBrand: "osd/goods/goodsManagement/exportBrandTemplate",//商品品牌
    goodsCloudTank: "osd/goods/goodsManagement/exportGoodsCloudTemplate",//商品云库
    colaOrder: "osd/order/orderExport/exportOrderCloudTemplate",// 可乐订单
    systemCoupon: "osd/order/odCoupon/exportConsumerInfo" // 系统优惠券
}


// 数据导入地址
let dataImporting = {
    deviceInfo: "osd/equipment/euq/importEquDetails",//设备列表
    goodsBrand: "osd/goods/goodsManagement/importBrand",//商品品牌
    goodsCloudTank: "osd/goods/goodsManagement/importGoodsCloud",//商品云库
    colaOrder: "osd/order/orderExport/importOrderCloudTemplate",// 可乐订单
    systemCoupon: "osd/order/odCoupon/importConsumerInfo" // 系统优惠券
}



// 判断页面权限和按钮权限
function permission(nav) {
    let data = JSON.parse(sessionStorage.getItem("asdNav"));
    let content = "";
    for (const i in data) {
        if (data[i].perms == nav) {
            content = data[i];
            break
        } else {
            for (const y in data[i].children) {
                if (data[i].children[y].perms == nav) {
                    content = data[i].children[y];
                    break
                } else {
                    for (const x in data[i].children[y].children) {
                        if (data[i].children[y].children[x].perms == nav) {
                            content = data[i].children[y].children[x];
                            break
                        }
                    }
                }
            }
        }
    }
    return content
}
function getIsBtn(data) {
    let btn = {};
    if (!judgment(data.children)) {
        data.children.forEach((item) => {
            btn[item.perms] = item;
        });
    }
    return btn
}
function getPermission(data) {
    if (judgment(data)) {
        router.replace({ path: "/" });
    } else {
        if (data.checked != true) {
            router.replace({ path: "/power" });
        }
    }
}



// 获取面包屑导航
function getBreadcrumb(nav) {
    let content = [];
    let data = JSON.parse(sessionStorage.getItem("asdNav"));
    for (const a in data) {
        for (const b in data[a].children) {
            if (data[a].children[b].perms == nav) {
                content.push(data[a].name);
                content.push(data[a].children[b].name);
                return content
            }
        }
    }
}




// js 获取时间
function getTime(type) {
    var time = new Date();
    var Month = "";
    var Day = "";
    if ((time.getMonth() + 1) < 10) {
        Month = "0" + (time.getMonth() + 1);
    } else {
        Month = time.getMonth() + 1;
    }
    if (time.getDate() < 10) {
        Day = "0" + time.getDate();
    } else {
        Day = time.getDate();
    }
    if (type == "yyyy") {//年
        return time.getFullYear();
    } else if (type == "mm") {//月
        return Month
    } else if (type == "dd") {//日
        return Day
    } else if (type == "yyyy-mm") {// 年月
        return time.getFullYear() + "-" + Month
    } else if (type == "yyyy-mm-dd") {// 年月日
        return time.getFullYear() + "-" + Month + "-" + Day;
    } else if (type == "ww") {//最近一周
        var week = [];
        week.push(time.getFullYear() + "-" + Month + "-" + Day);
        for (let a = 0; a < 6; a++) {
            time.setDate(time.getDate() - 1);
            var myMonth = time.getMonth() + 1;
            if (myMonth < 10) {
                myMonth = '0' + myMonth;
            }
            var myDay = time.getDate();
            if (myDay < 10) {
                myDay = '0' + myDay;
            }
            week.push(time.getFullYear() + "-" + myMonth + "-" + myDay);
        }
        return week.reverse();
    } else {//几天前
        var data = [];
        data.push(time.getFullYear() + "-" + Month + "-" + Day);
        for (let a = 0; a < type; a++) {
            time.setDate(time.getDate() - 1);
            var myMonth = time.getMonth() + 1;
            if (myMonth < 10) {
                myMonth = '0' + myMonth;
            }
            var myDay = time.getDate();
            if (myDay < 10) {
                myDay = '0' + myDay;
            }
            data.push(time.getFullYear() + "-" + myMonth + "-" + myDay);
        }
        return data.reverse();
    }
}


// 计算两个日期之间的天数
function getDays(startDate, endDate) {
    const sDate = Date.parse(startDate)
    const eDate = Date.parse(endDate)
    if (sDate > eDate) {
        return 0
    }
    if (sDate === eDate) {
        return 1
    }
    const days = (eDate - sDate) / (1 * 24 * 60 * 60 * 1000)
    return days
}



// js获取ulr传参
function getQueryString(val) {
    const w = location.hash.indexOf('?');
    const query = location.hash.substring(w + 1);

    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] == val) { return pair[1]; }
    }

    return null;
    // var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    // var r = window.location.search.substr(1).match(reg);
    // if (r != null) {
    //     return unescape(r[2]);
    // }
    // return null;
}


// 计算表格高度，固定表头
function altitude() {
    let offsetHeight = document.body.offsetHeight;// 屏幕高度
    let content = Number(offsetHeight) - 335;
    return content
}


// 表单验证判断是否为空
function judgment(data) {
    if (data == "" || data == undefined || data == null || data == NaN || data == "undefined" || data == "null" || data == "NaN" || data == {} || data == "{}" || JSON.stringify(data) == "{}" || data == [] || data == "[]" || data.length <= 0) {
        return true;
    } else {
        return false;
    }
}


// 手机号码验证
function iPhone(data) {
    let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!reg.test(data)) {
        return true;
    } else {
        return false;
    }
}


// 身份证号码正则
function idCard(data) {
    let reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (!reg.test(data)) {
        return true;
    } else {
        return false;
    }
}


// 价格金额正则判断处理
function money(data) {
    let reg = /^[0-9]+.?[0-9]*/;
    if (!reg.test(data)) {
        return data = "";
    } else {
        return Number(data).toFixed(2);
    }
}



// js递归处理连级选择器筛选条件
function Recursion(data) {
    for (const key in data) {
        if (judgment(data[key].children)) {
            delete data[key].children;
        } else {
            Recursion(data[key].children);
        }
    }
}





// 选择器筛选条件处理
function selector(name, value, data) {
    let content = {}
    data.unshift(content);
    data[0][name] = "全部"
    data[0][value] = "";
}



// 图片添加域名处理
function Image(data) {
    return DomainName + data;
}

// 处理列表图片地址
function ImageList(data, img) {
    let defaultImg = require("@/assets/img/default.png")
    if (!judgment(data)) {
        data.forEach(item => {
            if (!judgment(item[img])) {
                if (typeof (item[img]) == "string") {//如果是字符串转为数组
                    item[img] = item[img].split(",");
                    item[img].forEach((content, index) => {
                        if (item[img][index].indexOf("/") == -1) {
                            item[img][index] = defaultImg
                        } else {
                            item[img][index] = Image(content)
                        }
                    })
                } else if (Array.isArray(item[img])) {//如果是数组直接处理
                    item[img].forEach((content, index) => {
                        if (item[img][index].indexOf("/") == -1) {
                            item[img][index] = defaultImg;
                        } else {
                            item[img][index] = Image(content)
                        }
                    })
                }
            } else {
                item[img] = [defaultImg];
            }
        });
    }
}


// 处理编辑图片地址
function editImageList(data) {
    let imgList = [];
    data.forEach((item, index) => {
        if (data[index].indexOf("https://") != -1) {
            imgList.push(item)
        }
    })
    return imgList
}
function editImageShowList(data) {
    let imgList = [];
    data.forEach((item, index) => {
        if (data[index].indexOf("https://") != -1) {
            imgList.push(item)
        }
    })
    return imgList
}




// 处理金额换算
function moneyConversion(data, money) {
    if (!judgment(data)) {
        if (Array.isArray(data)) {
            data.forEach(item => {
                if (judgment(item[money])) {
                    item[money] = 0
                }
                item[money] = (Number(item[money]) / 100).toFixed(2);
            })
        } else {
            if (judgment(data[money])) {
                data[money] = 0;
            }
            data[money] = (Number(data[money]) / 100).toFixed(2);
        }
    }
}


// 截取商品数量单位拼接
function intercepting(data, countIntegral, countScrappy, countSum) {
    data.forEach((item) => {
        let boxSpec = item.boxSpec.substr(-1);
        let spec = item.spec.substr(-1);
        item[countIntegral] = item[countIntegral] + boxSpec;
        item[countScrappy] = item[countScrappy] + spec;
        item[countSum] = item[countSum] + spec;
    })
}
function interceptings(data, countIntegral, countScrappy, beforeCount, afterCount) {
    data.forEach((item) => {
        let boxSpec = item.boxSpec.substr(-1);
        let spec = item.spec.substr(-1);
        item[countIntegral] = item[countIntegral] + boxSpec;
        item[countScrappy] = item[countScrappy] + spec;
        item[beforeCount] = item[beforeCount] + spec;
        item[afterCount] = item[afterCount] + spec;
    })
}



// 处理首页饼图名称
function chartName(data, name, value) {
    if (!judgment(data)) {
        data.forEach(item => {
            if (item[name] == 1) {
                item[name] = "微信"
            } else if (item[name] == 2) {
                item[name] = "支付宝"
            } else if (item[name] == 3) {
                item[name] = "其他"
            }
            item[name] = item[name] + "     " + item[value];
        })
    }
}



// 处理省市区地址
function getSite(data) {
    let site = siteJs.site;
    for (const a in site) {
        for (const b in site[a].children) {
            for (const c in site[a].children[b].children) {
                if (site[a].children[b].children[c].regionCode == data) {
                    return site[a].regionName + site[a].children[b].regionName + site[a].children[b].children[c].regionName;
                }
            }
        }
    }
}
function disposeSite(data, value) {
    data.forEach(item => {
        if (!judgment(item[value])) {
            item[value] = item[value].split(",");
            item.addressInfo = getSite(item[value][item[value].length - 1]) + item.equAddr
        } else {
            item.addressInfo = item.equAddr
        }
    })
}
function disposeSiteS(data, value, value2) {
    data.forEach(item => {
        if (!judgment(item[value])) {
            item[value] = item[value].split(",");
            item.addressInfo = getSite(item[value][item[value].length - 1]) + item[value2]
        } else {
            item.addressInfo = item[value2]
        }
    })
}



// 复制内容方法
function copyContent(data) {
    const save = (e) => {
        e.clipboardData.setData("text/plain", data);
        e.preventDefault(); //阻止默认行为
    };
    const once = {
        once: true,
    };
    document.addEventListener("copy", save, once); // 添加一个copy事件,当触发时执行一次,执行完删除
    document.execCommand("copy"); //执行copy方法
    Message.success("内容复制成功！");
}




// 表单验证判断输入的是否是正整数
function integer(data) {
    // let regExp = /[^\d\.]/g;//正浮点数
    let regExp = /^[1-9]+[0-9]*$/;//正整数
    if (!new RegExp(regExp).test(data)) {
        return true
    } else {
        return false;
    }
}





export default {
    DomainName,
    downloadAddress,
    dataImporting,
    permission,
    getIsBtn,
    getPermission,
    getBreadcrumb,
    getTime,
    getDays,
    getQueryString,
    altitude,
    judgment,
    iPhone,
    idCard,
    Recursion,
    selector,
    money,
    Image,
    ImageList,
    editImageList,
    editImageShowList,
    moneyConversion,
    intercepting,
    interceptings,
    chartName,
    disposeSite,
    disposeSiteS,
    copyContent,
    integer
}
