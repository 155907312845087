import Vue from "vue";
import Vuex from "vuex";
import tagsView from './modules/tagsView'
import settings from './modules/settings'
import getters from './getters'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    UserInformation: {},//用户信息
    systemInfo: {},// 系统信息
    navigation: {},//导航信息
  },
  mutations: {
    updatedUserInformation(state, val) {
      state.UserInformation = val;
    },
    updatedSystemInfo(state, val) {
      state.systemInfo = val;
    },
    updatedNavigation(state, val) {
      state.navigation = val;
    }
  },
  actions: {},
  modules: {
    tagsView,
    settings
  },
  getters
});
